.DayPicker {

  &__withBorder{
    border-radius: 8px;
    box-shadow: 0 0 0 1px #D4DDE6;
  }

  &_transitionContainer {
    // I havent found any better way to do this
    height: 370px !important;
  }
}

.DayPickerNavigation {

  &_button {
    position: absolute;

    &:first-child {
      left: 35px;
      top: 24px;
    }

    &:last-child {
      right: 35px;
      top: 24px;
    }

    &__default {
      border: none;

      &:active {
        outline: none;
      }

      &:hover {
        border: none;
      }
    }
  }
}

.CalendarMonth {
  &_caption {

    strong {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: $textColor;
    }
  }

  &_table {
    border-collapse: separate;
    border-spacing: 0 6px;
  }
}

.CalendarDay {

  &__default {
    border: none;

    &:hover {
      border-radius: 50%;
    }
  }

  &__today {
    border-radius: 50%;
    border: 1px solid $primary;
  }

  &__blocked {

    &_calendar {
      background: white;
      text-decoration: line-through;
      color: rgba($textColor, 0.6);
    }
  }

  &__hovered {

    &_span {
      color: $textColor;
      background: rgba($primary, 0.05);

      &:hover {
        border: none;
        background: rgba($primary, 0.1);
      }
    }
  }

  &__highlighted_calendar {
    background: #ffffff;
    border-radius: 50%;
    color: rgba($textColor, 0.6);
  }

  &__highlighted_calendar:hover {
    background: rgba($primary, 0.1);
    color: #484848
  }

  &__highlighted_calendar:active {
    background: rgba($primary, 0.1);
    color: #484848
  }

  &__selected {
    border: none;
    background: $primary;
    border-radius: 50%;
    position: relative;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba($primary, 0.05);
    }

    &_start {
      color: #ffffff
    }
    &_end {
      color: #ffffff;

      &:hover {
        background: rgba($primary, 0.8);
      }
    }

    &_start {
      &:before {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }

    &_end {
      &:before {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }

    &_span {
      color: $textColor;
      background: rgba($primary, 0.05);

      &:hover {
        background: $primary;
        border: none;
      }
    }

    &:hover {
      border: none;
    }
  }
}

.CalendarMonth {

  tbody {

    tr {
      .CalendarDay__selected_span:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .CalendarDay__selected_span:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

    }
  }
}

/* .CalendarDay__highlighted_calendar {
  background: #ffffff;
  border-radius: 50%;
  color: $textColor;
  opacity: 0.6;
} */
/* 
.CalendarDay__highlighted_calendar:hover {
  background: rgba($primary, 0.1);
  color: #484848
} */

/* .CalendarDay__highlighted_calendar:active {
  border: none;
  background: $primary;
  border-radius: 50%;
} */

